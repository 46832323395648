"use client";
import React from "react";

// importing redux
import type { RootState } from "@/app/GlobalRedux/Store";

import Header from "@/app/(Global)/Components/HomeHeader";
import LandingPage from "@/app/Home/Components/Home";
import Footer from "@/app/(Global)/Components/Footer";

import useAuthStatus from "../auth/hook/useAuthStatus";
import { useSelector } from "react-redux";

export default function HomePage() {
  const { isLoggedIn } = useAuthStatus();

  const theme = useSelector((state: RootState) => state.theme.themeValue);

  return (
    <>
      <Header theme={theme} isLoggedIn={isLoggedIn} />
      <LandingPage theme={theme} isLoggedIn={isLoggedIn} />
      <Footer theme={theme} isLoggedIn={isLoggedIn} />
    </>
  );
}
